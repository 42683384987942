import React from 'react'
import styled from 'styled-components'
import { media } from 'components/Grid'
import LogoImage from 'assets/images/logo.svg'
import { Link } from 'react-router-dom'

const LogoLink = ({ className, appName }) => (
  <Link className={className} to="/">
    {appName}
  </Link>
)

const Logo = styled(LogoLink)`
  width: 7.25rem;
  height: 2rem;
  background: url(${LogoImage}) top left /cover no-repeat;
  font-size: 0;
  display: block;

  ${media.tablet`
    width: 9.75rem;
    height: 3.5rem;
  `}
`

export default Logo
