import React from 'react'
import styled from 'styled-components'
import { media, Container } from 'components/Grid'
import Button from 'components/Button'

const BannerItem = ({ className, banner }) => {
  return (
    <li
      className={className}
      style={{backgroundImage:`url(${banner.acf.image})`}}>
      <Container>
        {/* <iframe
          title="background-video"
          src="https://www.youtube.com/embed/VefhvKWhrT8?version=3&playlist=VefhvKWhrT8&showinfo=0&controls=0&modestbranding=1&autohide=1&loop=1&wmode=opaque&disablekb=1&rel=0&fs=0&autoplay=1&mute=1"
          frameborder="0">
        </iframe> */}
        
        <h2 dangerouslySetInnerHTML={{ __html: banner.acf.description }} />

        {banner.acf.cta_label &&
          <Button url={banner.acf.cta_url}>
            {banner.acf.cta_label}
          </Button>
        }
      </Container>
    </li>
  )
}

const StyledBannerItem = styled(BannerItem)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100vh;
  padding: 0 2rem 0 0;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  transition: visibility 1s, opacity 1s;

  ${props => props.isActive && `
    visibility: visible;
    opacity: 1;
  `}

  /* &:after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,.5);
    z-index: 20;
  } */

  /* iframe {
    display: none;
    width: 2005.3333333333333px;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 0;

    ${media.desktop`
      display: block;
    `}
  } */

  h2 {
    margin: 0 0 3rem;
    font-family: 'Poppins';
    font-size: 2.2rem;
    font-weight: 100;
    line-height: 3rem;
    color: white;
    position: relative;
    z-index: 30;

    ${media.tablet`
      max-width: 80vw;
      font-size: 4rem;
      line-height: 5rem;
    `}

    ${media.desktophd`
      max-width: 50vw;
    `}
  }
`

export default StyledBannerItem
