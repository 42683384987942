import React from 'react'
import styled from 'styled-components'
import { media } from 'components/Grid'
import { Link } from 'react-router-dom'

const FeaturedAllotmentItem = ({ className, allotment }) => {
  return (
    <li className={className}>
      <Link to={`/loteamento/${allotment.slug}`} className="allotment-wrapper">
        <div style={{ backgroundImage: `url(${allotment.image})` }}></div>
        <div>
          <h3>{allotment.title} <br />
            <span>
              {allotment.region}
            </span>
          </h3>

          <p>{allotment.short_description}</p>

          <p className="allotment-link">Ver loteamento</p>
        </div>
      </Link>
    </li>
  )
}

const StyledFeaturedAllotmentItem = styled(FeaturedAllotmentItem)`
  flex: 0 1 100%;
  margin-bottom: 2rem;

  ${media.tablet`
    flex: 0 1 50%;
    padding: 1rem;
  `}

  ${media.desktop`
    flex: 0 1 33%;
  `}

  ${media.desktophd`
    flex: 0 1 25%;
  `}

  .allotment-wrapper {
    display: block;
    border: 1px solid #f5f5f5;
    border-radius: 5px;
    box-shadow: 0 5px 10px rgba(0,0,0,.1);
    overflow: hidden;
    text-decoration: none;
    transition: transform .35s ease;

    > div:first-child {
      width: 100%;
      height: 15rem;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      margin-bottom: 1rem;
    }

    > div:last-child {
      padding: 1rem 1.5rem;
    }

    &:focus,
    &:hover {
      transform: translateY(-1rem);
    }
  }

  h3 {
    margin: 0 0 30px;
    font-family: 'Poppins';
    font-weight: 100;
    font-size: 1.35rem;
    color: #838286;

    span {
      font-family: 'Rubik';
      font-size: 1rem;
      font-weight: 300;
      color: #c4c4c4;
    }
  }

  p {
    margin: 0 0 40px;
    font-size: 1.1rem;
    color: #838256;
  }

  .allotment-link {
    font-size: 1.1rem;
    font-weight: 400;
    text-decoration: none;
    text-transform: lowercase;
    color: #b38c1a;
  }

  ${props => props.isHomePage && `
    margin-bottom: 0;

    ${media.tablet`
      padding: 0;

      &:nth-child(2n) .allotment-wrapper div:first-child {
        order: 2;
      }
      
      &:nth-child(2n) .allotment-wrapper div:last-child {
        text-align: right;
      }
    `}

    .allotment-wrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 100%;
      border: none;
      border-radius: 0;
      box-shadow: none;
      text-decoration: none;
      transition: none;

      > div {
        ${media.tablet`
          flex: 0 0 50%;
        `}

        &:last-child {
          padding: 1.25rem;
  
          ${media.tablet`
            padding: 0 3rem;
          `}
        }
      }

      > div:first-child {
        margin-bottom: 0;
        
        ${media.tablet`
          height: 20rem;
        `}

        ${media.desktop`
          height: 30rem;
        `}
      }

      > div:last-child {
        padding: 1rem 1.5rem;
      }

      &:focus,
      &:hover {
        transform: none;
      }
    }

    h3 {
      font-size: 2rem;
    }
  `}
`

export default StyledFeaturedAllotmentItem