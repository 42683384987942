import React, { Component } from 'react'
import styled from 'styled-components'
import BannerItem from './BannerItem'

class Banner extends Component {
  constructor(props) {
    super()

    this.state = {
      activeBanner: 0
    }
  }

  nextBanner() {
    this.setState((prevState, props) => {
      if (prevState.activeBanner === props.banners.length - 1) {
        return {
          activeBanner: 0
        }
      }

      return {
        activeBanner: prevState.activeBanner + 1
      }
    })
  }

  componentDidMount() {
    window.setInterval(() => {
      this.nextBanner()
    }, 5000)
  }

  render() {
    return (
      <ul className={this.props.className}>
        {this.props.banners.map((banner, index) => (
          <BannerItem
            key={`banner-${index}`}
            banner={banner}
            isActive={index === this.state.activeBanner} />
        ))}
      </ul>
    )
  }
}

const StyledBanner = styled(Banner)`
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100vh;
  background: #000000;
  background: -moz-linear-gradient(top, #000000 0%, #231c1a 100%);
  background: -webkit-linear-gradient(top, #000000 0%,#231c1a 100%);
  background: linear-gradient(to bottom, #000000 0%,#231c1a 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#231c1a',GradientType=0 );
  position: relative;
`

export default StyledBanner
