import React, { Component } from 'react'
import api from 'api/api'
import Input from 'components/Input'
import Textarea from 'components/Textarea'
import Button from 'components/Button'

class Form extends Component {
  constructor(props){
    super(props)

    this.state = {
      formData: {},
      isSendingForm: false,
      feedback: {
        message: '',
        status: ''
      }
    }
  }

  updateField = (event, fieldName) => {
    event.persist()

    this.setState(prevState => {
      return {
        formData: {
          ...prevState.formData,
          [fieldName]: event.target.value
        }
      }
    })
  }

  onSubmit = () => {
    // @todo validate form fields before send

    this.setState({ isSendingForm: true })

    if (!Object.keys(this.state.formData).length > 0) {
      return this.setState({
        isSendingForm: false,
        feedback: {
          status: 'error',
          message: 'Por favor, preencha todos os dados.'
        }
      })
    }
    
    for (let field in this.state.formData) {
      if (field === '') {
        return this.setState({
          isSendingForm: false,
          feedback: {
            status: 'error',
            message: 'Por favor, preencha todos os dados.'
          }
        })
      }
    }

    api.sendEmail(this.state.formData).then(response => {
      if (response.data === true) {
        return this.setState({
          isSendingForm: false,
          feedback: {
            status: 'sucess',
            message: 'Obrigado pelo contato, retornaremos o mais breve possível.'
          }
        })
      }

      return this.setState({
        isSendingForm: false,
        feedback: {
          status: 'error',
          message: 'Ops, algo deu errado, tente novamente por favor.'
        }
      })
    })
  }

  render() {
    return (
      <form>
        <p className={this.state.feedback.status}>
          {this.state.feedback.message}
        </p>

        <Input
          name="name"
          label="Seu nome:"
          type="text"
          onChange={this.updateField} 
        />
  
        <Input
          name="email"
          label="Seu e-mail:"
          type="email" 
          onChange={this.updateField}
        />
  
        <Input
          name="phone"
          label="Seu telefone:"
          type="text"
          onChange={this.updateField}
        />

        {this.props.hasContract && 
          <Input
            name="contract"
            label="Contrato:"
            type="text"
            onChange={this.updateField}
          />
        }

        {this.props.hasUpdate && 
          <Input
            name="update"
            label="Dados para atualização:"
            type="text"
            onChange={this.updateField}
          />
        }
  
        <Textarea
          name="message"
          label="Mensagem:"
          onChange={this.updateField}
        />

        <Button
          callback={this.onSubmit}
          disabled={this.state.isSendingForm}>
          {this.state.isSendingForm ? 'Enviando...' : 'Enviar'}
        </Button>
      </form>
    )
  }
}

export default Form