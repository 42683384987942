import React from 'react'
import styled from 'styled-components'
import { media } from 'components/Grid'
import { Link } from 'react-router-dom'

const MenuItem = (props) => {
  if (props.item.target) {
    return (
      <li className={props.className}>
        <a href={props.item.url} target="_blank">
          {props.item.label}
        </a>
      </li>
    )
  }

  return (
    <li className={props.className}>
      {props.item.children && props.item.children.length > 0 ? (
        <button onClick={(event) => {
          event.stopPropagation()
          props.toggleDropdownFunction(props.index)
        }}>
          {props.item.label}
        </button>
      ) : (
          <Link to={props.item.url}>
            {props.item.label}
          </Link>
        )
      }

      {props.item.children && props.item.children.length > 0 &&
        <ul>
          {props.item.children.map((child, index) => (
            <li key={`child-${index}`}>
              <Link to={child.url}>
                {child.label}
              </Link>
            </li>
          ))}
        </ul>
      }
    </li>
  )
}

const StyledMenuItem = styled(MenuItem)`
  > a,
  > button,
  > ul > li > a {
    padding: 1rem;
    background: none;
    border: none;
    display: block;
    font-size: 1.2rem;
    font-weight: 300;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: .125rem;
    color: white;
    cursor: pointer;
    outline: none;

    &:active {
      background: none;
    }
  }

  > ul {
    display: none;
    padding: 0;
    margin: 0;
    list-style: none;
  }

  ${props => props.item.isOpen && `
    > ul {
      display: block;
    }

    > ul > li > a {
      background
    }
  `}

  > ul > li > a {
    font-size: 1rem;
    text-transform: none;
    letter-spacing: 0;
    opacity: .75;

    ${media.desktop`
    opacity: 1;
    `}
  }

  ${props => props.isMobileOnly && `
    ${media.desktop`
      display: none;
    `}
  `}

  ${media.desktop`
    position: relative;

    > ul {
      width: max-content;
      max-width: 12.5rem;
      position: absolute;
      top: 100%;
      left: 0;
      text-align: left;
    }

    > ul > li {
      border-bottom: .0625rem solid rgba(255, 255, 255, .25);

      &:last-child {
        border: none;
      }
    }

    > a,
    > button {
      padding: .6rem 2rem;
      font-size: .8125rem;
      font-weight: 400;
      letter-spacing: .125rem;
    }

    &:last-child > a,
    &:last-child > button {
      padding-right: 0;
    }

    > ul > li > a {
      background: black;
      transition: background .2s;

      &:hover {
        background: rgba(0,0,0, .9);
      }
    }
  `}
`

export default StyledMenuItem
