import React, { Component } from 'react'
import styled from 'styled-components'
import { Container, Row, Column } from 'components/Grid'
import PageHeader from 'components/PageHeader'
import api from 'api/api'
import FacebookLogoBlack from 'assets/images/facebook-logo_black.svg'
import InstagramLogoBlack from 'assets/images/instagram-logo_black.svg'
import Form from 'components/Form'

class Contact extends Component {
  constructor(props) {
    super()

    this.state = {
      pageSlug: 'contato',
      pageTitle: '',
      pageContent: '',
      office_hours: '',
      email: '',
      phones: '',
      address: '',
      facebook: '',
      instagram: ''
    }
  }

  componentWillMount() {
    window.scrollTo(0, 0)
    
    api.getPages('', this.state.pageSlug).then(results => {
      if (results.length) {
        this.setState({
          pageTitle: results[0].title.rendered,
          pageContent: results[0].content.rendered
        })
      }
    })

    api.getGeneralConfig().then(results => {
      this.setState({
        office_hours: results.acf.office_hours,
        email: results.acf.email,
        phones: results.acf.phones,
        address: results.acf.address,
        facebook: results.acf.facebook_url,
        instagram: results.acf.instagram_url
      })
    })
  }

  render() {
    return (
      <div>
        <PageHeader pageTitle={this.state.pageTitle} />
  
        <main className={this.props.className}>
          <Container>
            <Row>
              <Column>
                <div dangerouslySetInnerHTML={{__html: this.state.pageContent}}></div>

                <h3>Redes sociais:</h3>

                <ul className="social-media">
                  <li>
                    <a 
                      href={this.state.facebook}
                      target="_blank"
                      rel="noopener noreferrer">
                      <img src={FacebookLogoBlack} alt="Facebook Logo" />
                    </a>
                  </li>
                  <li>
                    <a 
                      href={this.state.instagram}
                      target="_blank"
                      rel="noopener noreferrer">
                      <img src={InstagramLogoBlack} alt="Instagram Logo" />
                    </a>
                  </li>
                </ul>
              </Column>
              <Column>
                <Form />
              </Column>
            </Row>
          </Container>
        </main>
      </div>
    )
  }
}

const StyledContact = styled(Contact)`
  min-height: 50vh;

  a {
    color: #333;
  }

  .social-media {
    padding: 0;
    margin: 0 0 3rem;

    > li {
      margin: 0 1rem 0 0;
      display: inline-block;
      color: black;
    }
  }
`

export default StyledContact
