import React from 'react'
import styled from 'styled-components'
import { media } from 'components/Grid'
import MenuItem from './MenuItem'

const MenuList = (props) => {
  return (
    <ul className={props.className}>
      {props.items.map((item, index) => (
        <MenuItem
          key={`item-${index}`}
          item={item}
          index={index}
          toggleDropdownFunction={props.toggleDropdownFunction}
        />
      ))}

      {props.secondaryItems.map((item, index) => (
        <MenuItem
          item={item}
          index={index}
          isMobileOnly={true}
          key={`secondary-item-${index}`}
        />
      ))}
    </ul>
  )
}

const StyledMenuList = styled(MenuList)`
  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
  background: rgba(0, 0, 0, .96);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  list-style: none;
  transform: translateX(100%);
  transition: transform .35s ease;

  ${props => props.isOpen && `
    transform: translateX(0);
  `}

  ${media.desktop`
    width: initial;
    height: initial;
    background: none;
    position: static;
    flex-direction: row;
    justify-content: flex-end;
    text-align: center;
    transform: none;
    transition: transform .35s ease;
  `}
`

export default StyledMenuList