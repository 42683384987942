import styled from 'styled-components'

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${props => props.isMobile && `
    div {
      width: inherit;
    }
  `}

  ${props => props.isFullWidth && `
    justify-content: space-between;
  `}

  ${props => props.isVerticallyCentered && `
    align-items: center;
  `}
`

export default Row
