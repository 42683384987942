import React, { Component } from 'react'
import styledNormalize from 'styled-normalize'
import { createGlobalStyle } from 'styled-components'
import api from 'api/api'
import _ from 'lodash'

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from 'react-router-dom'

import Header from 'components/Header'
import Footer from 'components/Footer'

import Home from 'pages/Home'
import AboutUs from 'pages/AboutUs'
import Allotments from 'pages/Allotments'
import Allotment from 'pages/Allotment'
import SubscriptionUpdate from 'pages/SubscriptionUpdate'
import Tickets from 'pages/Tickets'
import Partnership from 'pages/Partnership'
import Contact from 'pages/Contact'

const GlobalStyle = createGlobalStyle`
  ${styledNormalize}

  @import url('https://fonts.googleapis.com/css?family=Rubik:300,400,500,700,900');
  @import url('https://fonts.googleapis.com/css?family=Poppins:100,300');

  body {
    height: 100vh;
    overflow-x: hidden;
    background-repeat: no-repeat;
    font-family: 'Rubik', sans-serif;
    font-weight: 300;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
  }

  * {
    box-sizing: border-box;
  }

  table {
    text-align: left;
  }

  th {
    font-weight: 500;
  }

  th, td {
    padding: .5rem;
    border-bottom: .125rem solid #f5f5f5;
  }
`

class App extends Component {
  constructor() {
    super()

    this.state = {
      appName: 'Status Loteadora',
      headerIsMinimized: false,
      headerBackground: '',
      mainMenu: {
        items: [
          {
            label: 'Início',
            url: '/'
          },
          {
            label: 'Loteamentos',
            url: '/loteamentos',
            children: [],
            isOpen: false
          },
          {
            label: 'Quem somos',
            url: '/quem-somos'
          },
          {
            label: 'Blog',
            url: 'http://blog.statusloteadora.com.br/',
            target: 'new_tab'
          },
          {
            label: 'Contato',
            url: '/contato'
          }
        ],
        isOpen: false
      },
      secondaryMenu: {
        items: [
          {
            label: '2ª via boleto',
            url: '/2a-via-de-boleto'
          },
          {
            label: 'Atualização cadastral',
            url: '/atualizacao-cadastral'
          },
          {
            label: 'Seja nosso parceiro',
            url: '/seja-nosso-parceiro'
          }
        ]
      },
      siteInfo: {
        office_hours: '',
        email: '',
        address: '',
        phones: '',
        facebook: '',
        instagram: ''
      }
    }
  }

  componentWillMount() {
    api.getRegions().then(results => {
      if (results.length > 0) {
        this.setState(prevState => {
          let regions = results.map(region => ({
            label: region.name,
            url: `/loteamentos/${region.slug}`
          }))

          let updatedItems = [
            ...prevState.mainMenu.items
          ]

          updatedItems[1].children = regions

          return {
            ...prevState,
            mainMenu: {
              ...prevState.mainMenu,
              items: updatedItems
            }
          }
        })
      }
    })

    api.getGeneralConfig().then(results => {
      this.setState({
        headerBackground: results.acf.header_background,
        siteInfo: {
          office_hours: results.acf.office_hours,
          email: results.acf.email,
          phones: results.acf.phones,
          address: results.acf.address,
          facebook: results.acf.facebook_url,
          instagram: results.acf.instagram_url
        }
      })
    })
  }

  componentDidMount() {
    window.addEventListener('scroll', _.debounce(() => {
      this.setState(prevState => {
        return {
          ...prevState,
          headerIsMinimized: window.pageYOffset > 100
        }
      })
    }), 250)

    window.addEventListener('click', () => {
      this.setState(prevState => {
        let updatedMenuItems = [
          ...prevState.mainMenu.items
        ]
  
        updatedMenuItems[1].isOpen = false
  
        return {
          ...prevState,
          mainMenu: {
            ...prevState.mainMenu,
            items: updatedMenuItems
          }
        }
      })
    })
  }

  toggleMainMenu = () => {
    this.setState(prevState => (
      {
        mainMenu: {
          ...prevState.mainMenu,
          isOpen: !prevState.mainMenu.isOpen
        }
      }
    ))
  }

  toggleDropdownMenu = (index) => {
    this.setState(prevState => {
      let updatedMenuItems = [
        ...prevState.mainMenu.items
      ]

      updatedMenuItems[index].isOpen = !updatedMenuItems[index].isOpen

      return {
        ...prevState,
        mainMenu: {
          ...prevState.mainMenu,
          items: updatedMenuItems
        }
      }
    })
  }

  render() {
    return (
      <div>
        <GlobalStyle />
        <Router>
          <Header
            appName={this.state.appName}
            isMinimized={this.state.headerIsMinimized}
            mainMenu={this.state.mainMenu}
            secondaryMenu={this.state.secondaryMenu}
            toggleMenuFunction={this.toggleMainMenu}
            toggleDropdownFunction={this.toggleDropdownMenu}
          />

          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/quem-somos/" component={AboutUs} />
            <Route path="/loteamentos/:region/" component={Allotments} />
            <Route path="/loteamento/:slug/" component={Allotment} />
            <Route path="/atualizacao-cadastral/" component={SubscriptionUpdate} />
            <Route path="/2a-via-de-boleto/" component={Tickets} />
            <Route path="/seja-nosso-parceiro/" component={Partnership} />
            <Route path="/contato/" component={Contact} />
            <Redirect to="/" />
          </Switch>

          <Footer
            siteInfo={this.state.siteInfo}
            mainMenu={this.state.mainMenu}
            secondaryMenu={this.state.secondaryMenu}
            isHomePage={window.location.pathname === '/'}
          />
        </Router>
      </div>
    )
  }
}

export default App;
