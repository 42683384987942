import React, { Component } from 'react'
import styled from 'styled-components'
import { Container, Row, Column } from 'components/Grid'
import PageHeader from 'components/PageHeader'
import api from 'api/api'
import FeaturedAllotments from 'components/FeaturedAllotments/'

class Allotments extends Component {
  constructor(props) {
    super()

    this.state = {
      currentRegion: {
        name: '',
        id: ''
      },
      allotments: [],
    }
  }

  changeRegion() {
    api.getRegions(this.props.match.params.region).then(results => {
      if (results.length) {
        this.setState({
          currentRegion: {
            name: results[0].name,
            id: results[0].id
          }
        })
      }
    }).then(() => {
      api.getAllotments('', this.state.currentRegion.id).then(results => {
        if (results.length) {
          this.setState({
            allotments: results
          })
        }
      })
    })
  }

  componentWillMount() {
    window.scrollTo(0, 0)
    this.changeRegion()
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.region !== prevProps.match.params.region) {
      this.changeRegion()
    }
  }

  render() {
    return (
      <div>
        <PageHeader pageTitle={this.state.currentRegion.name} />

        <main className={this.props.className}>
          <Container>
            <Row>
              <Column>
                <FeaturedAllotments allotments={this.state.allotments} />
              </Column>
            </Row>
          </Container>
        </main>
      </div>
    )
  }
}

const StyledAllotments = styled(Allotments)`
  min-height: 50vh;
`

export default StyledAllotments
