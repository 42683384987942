import React from 'react'
import styled from 'styled-components'
import FeaturedAllotmentItem from './FeaturedAllotmentItem'

const FeaturedAllotments = ({ className, allotments, isHomePage }) => {
  return (
    <section className={className}>
      <ul className="featured-allotment__list">
        {allotments.map((allotment, index) => (
          <FeaturedAllotmentItem
            key={`allotment-${index}`}
            allotment={allotment}
            isHomePage={isHomePage}
          />
        ))}
      </ul>
    </section>
  )
}
const StyledFeaturedAllotments = styled(FeaturedAllotments)`
  position: relative;

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;

    ${props => props.isHomePage && `
      display: block;
    `}
  }
`

export default StyledFeaturedAllotments
