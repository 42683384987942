import React, { Component } from 'react'
import styled from 'styled-components'
import { media, Container, Row, Column } from 'components/Grid'
import PageHeader from 'components/PageHeader'
import Form from 'components/Form'
import api from 'api/api'
import { Progress } from 'react-sweet-progress'
import 'react-sweet-progress/lib/style.css'

class Allotment extends Component {
  constructor(props) {
    super()

    this.state = {
      title: '',
      image: '',
      description: '',
      type: '',
      region: '',
      status: '',
      lots_number: 0,
      sales: {
        phone: '',
        address: ''
      },
      gallery: [],
      progress: {
        electrical_network: 0,
        guides_gutters: 0,
        landscaping: 0,
        paving: 0,
        sewerage_system: 0,
        water_network: 0
      },
      map: '',
      tour: ''
    }
  }

  componentWillMount() {
    api.getAllotments(this.props.match.params.slug, '').then(results => {
      if (results.length) {
        this.setState({
          title: results[0].title,
          image: results[0].image,
          description: results[0].description,
          type: results[0].type,
          region: results[0].region,
          status: results[0].status,
          lots_number: results[0].lots_number,
          sales: {
            phone: results[0].sales.phone,
            address: results[0].sales.address
          },
          gallery: results[0].gallery,
          progress: {
            electrical_network: results[0].progress.electrical_network,
            guides_gutters: results[0].progress.guides_gutters,
            landscaping: results[0].progress.landscaping,
            paving: results[0].progress.paving,
            sewerage_system: results[0].progress.sewerage_system,
            water_network:results[0].progress.water_network 
          },
          map: results[0].map,
          tour: results[0].tour,
        })
      }
    })
  }

  render() {
    return (
      <div>
        <PageHeader pageTitle={this.state.title} />

        <main className={this.props.className}>
          <Container>
            <Row>
              <Column>
                <img
                  className="allotment-image"
                  src={this.state.image}
                  alt={this.state.title}
                />
              </Column>
              <Column>
                <p className="allotment-description" dangerouslySetInnerHTML={{ __html: this.state.description }}></p>

                <table className="allotment-basic-info">
                  <tbody>
                    <tr>
                      <th>Tipo:</th>
                      <td>{this.state.type}</td>
                    </tr>
                    <tr>
                      <th>Região:</th>
                      <td>
                        {this.state.region}
                      </td>
                    </tr>
                    <tr>
                      <th>Status:</th>
                      <td>{this.state.status}</td>
                    </tr>
                    <tr>
                      <th>Nº de lotes:</th>
                      <td>{this.state.lots_number}</td>
                    </tr>
                  </tbody>
                </table>
              </Column>
            </Row>
          </Container>

          <section className="allotment-sales-info">
            <Container>
              <Row>
                <Column isCenterAligned>
                  <h3>Plantão de vendas</h3>

                  <h4>Ligue agora:</h4>
                  <p>{this.state.sales.phone}</p>

                  <h4>Escritório de vendas:</h4>
                  <p>{this.state.sales.address}</p>
                </Column>
              </Row>
            </Container>
          </section>

          <section className="allotment-gallery">
            <Container>
              <Row>
                <Column isCenterAligned>
                  <h3>Galeria de imagens:</h3>

                  <ul>
                    {this.state.gallery && this.state.gallery.map((image, index) => (
                      <li key={`image-${index}`}>
                        <a href={image.sizes.large}>
                          <img src={image.sizes.medium_large} alt={image.title} />
                        </a>
                      </li>
                    ))}
                  </ul>
                </Column>
              </Row>
            </Container>
          </section>

          <section className="allotment-construction-progress">
            <Container>
              <Row>
                <Column isCenterAligned>
                  <h3>Andamento das obras:</h3>

                  <ul>
                    <li>
                      <Progress
                        type="circle"
                        percent={this.state.progress.landscaping} 
                      />

                      <h4>Terraplanagem</h4>
                    </li>

                    <li>
                      <Progress
                        type="circle"
                        percent={this.state.progress.water_network} 
                      />

                      <h4>Rede de água</h4>
                    </li>

                    <li>
                      <Progress
                        type="circle"
                        percent={this.state.progress.sewerage_system} 
                      />

                      <h4>Rede de esgoto</h4>
                    </li>

                    <li>
                      <Progress
                        type="circle"
                        percent={this.state.progress.electrical_network} 
                      />

                      <h4>Rede elétrica</h4>
                    </li>

                    <li>
                      <Progress
                        type="circle"
                        percent={this.state.progress.paving} 
                      />

                      <h4>Pavimentação</h4>
                    </li>

                    <li>
                      <Progress
                        type="circle"
                        percent={this.state.progress.guides_gutters} 
                      />

                      <h4>Guias e sarjetas</h4>
                    </li>
                  </ul>
                </Column>
              </Row>
            </Container>
          </section>

          <section className="allotment-map">
            <Container>
              <Row>
                <Column isCenterAligned>
                  <h3>Mapa de lotes:</h3>

                  <div className="allotment-map-container">
                    <iframe src={this.state.map} width="100%" height="450"></iframe>
                  </div>
                </Column>
              </Row>
            </Container>
          </section>

          {this.state.tour &&
            <section className="allotment-tour">
              <Container>
                <Row>
                  <Column isCenterAligned>
                    <h3>Tour 360º:</h3>

                    <div className="allotment-tour-container">
                      <iframe src={this.state.tour} width="100%" height="450"></iframe>
                    </div>
                  </Column>
                </Row>
              </Container>
            </section>
          }

          <section>
            <Container>
              <Row>
                <Column>
                  <h3>Dúvidas?</h3>
                  <p>Quer saber mais informações sobre o empreendimento? Entre em contato conosco através do formulário abaixo:</p>
                  <Form />
                </Column>
              </Row>
            </Container>
          </section>
        </main>
      </div>
    )
  }
}

const StyledAllotment = styled(Allotment)`
  min-height: 50vh;
  
  h3 {
    font-size: 1.5rem;
    font-weight: 500;
    margin: 1.5rem 0;
  }

  img {
    max-width: 100%;
  }

  .allotment-sales-info {
    padding: 2rem;
    margin: 2rem 0;
    background-color: #FFD455;

    ${media.tablet`
      margin: 3rem 0;
    `}

    h3,
    h4 {
      text-transform: uppercase;
      opacity: .8;
    }

    h3 {
      font-size: 2rem;
      font-weight: 700;
      margin: 0;
    }

    h4 {
      font-size: 1.5rem;
      font-weight: 500;
      margin: 1.5rem 0 .5rem;
    }

    p {
      font-size: 1.2rem;
      font-weight: 200;
      margin: 0;
      opacity: .65;
    }
  }

  .allotment-gallery {
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    li {
      display: inline-block;
      padding: .5rem;
      transition: transform .35s;

      ${media.tablet`
        width: 33%;
      `}

      ${media.desktop`
        width: 25%;
      `}

      &:focus,
      &:hover {
        transform: translateY(-10%);
      }
    }

    img {
      max-width: 100%;
      object-fit: cover;
      border-radius: .125rem;
      box-shadow: 0 3px 15px rgba(0,0,0,.15)
    }
  }

  .allotment-construction-progress {
    padding: 2rem 0;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    li {
      display: inline-block;
      margin: 1.5rem;
    }

    h4 {
      font-weight: 500;
      text-transform: uppercase;
    }
  }

  .allotment-map-container {
    width: 100%;
    height: 700px;

    .leaflet-container {
      height: 700px;
    }
  }
`

export default StyledAllotment
