import React from 'react'
import styled from 'styled-components'
import Logo from 'components/Logo'

import {
  media,
  Container,
  Row,
  Column
} from 'components/Grid'

import {
  HamburguerButton,
  MenuList,
  SecondaryMenu
} from 'components/MainNavigation'

const Header = (props) => {
  return (
    <header className={props.className}>
      <Container>
        <Row isFullWidth isVerticallyCentered isMobile>
          <Column>
            <Logo appName={props.appName} />
          </Column>

          <Column isRightAligned>
            <HamburguerButton
              isOpen={props.mainMenu.isOpen}
              onClick={() => props.toggleMenuFunction()} />

            <SecondaryMenu items={props.secondaryMenu.items} />

            <MenuList
              items={props.mainMenu.items}
              secondaryItems={props.secondaryMenu.items}
              isOpen={props.mainMenu.isOpen}
              toggleDropdownFunction={props.toggleDropdownFunction} />
          </Column>
        </Row>
      </Container>
    </header>
  )
}

const StyledHeader = styled(Header)`
  padding: 1.25rem 0;
  position: fixed;
  width: 100%;
  background-color: rgba(208, 12, 0, 0.8);
  z-index: 99999;
  transition: padding .35s, background .35s;

  ${props => props.isMinimized && `
    padding: .65rem 0;
  `}

  ${media.desktop`
    padding: 2.5rem 0;
  `}

  ${props => props.isMinimized && media.desktop`
    padding: 1rem 0;
  `}
`

export default StyledHeader
