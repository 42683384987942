import styled from 'styled-components'
import { media } from 'components/Grid'

const Column = styled.div`
  display: block;
  width: 100%;
  padding: .5rem;

  ${media.tablet`
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 1;
  `}

  ${props => props.isRightAligned && `
    text-align: right;
  `}

  ${props => props.isCenterAligned && `
    text-align: center;
  `}
`

export default Column
