import React from 'react'
import styled from 'styled-components'
import { media, Container } from 'components/Grid'
import Button from 'components/Button'

const AboutUsExcerpt = ({ className, content }) => {
  return (
    <section className={className}>
      <Container>
        <h3>{content.title}</h3>

        <div dangerouslySetInnerHTML={{__html: content.description}}></div>

        <Button url={content.cta_url}>
          {content.cta_label}
        </Button>
      </Container>
    </section>
  )
}

const StyledAboutUsExcerpt = styled(AboutUsExcerpt)`
  padding: 3.5rem 0;
  background: #f5f5f5;
  text-align: center;

  h3 {
    margin-bottom: 3rem;
    font-family: 'Poppins';
    font-size: 3rem;
    font-weight: 100;
    color: #231c1a;
  }

  p {
    margin-bottom: 40px;
    font-size: 1.1rem;
    font-weight: 300;
    line-height: 1.9rem;
    color: #838286;

    ${media.desktop`
      max-width: 50%;
      margin-left: auto;
      margin-right: auto;
    `}
    
  }
`

export default StyledAboutUsExcerpt