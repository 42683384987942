import React, { Component } from 'react'
import styled from 'styled-components'
import { Container, Row, Column } from 'components/Grid'
import PageHeader from 'components/PageHeader'
import api from 'api/api'
import Form from 'components/Form'

class SubscriptionUpdate extends Component {
  constructor(props) {
    super()

    this.state = {
      pageSlug: 'atualizacao-cadastral',
      pageTitle: '',
      pageContent: ''
    }
  }

  componentWillMount() {
    window.scrollTo(0, 0)
    
    api.getPages('', this.state.pageSlug).then(results => {
      if (results.length) {
        this.setState({
          pageTitle: results[0].title.rendered,
          pageContent: results[0].content.rendered
        })
      }
    })
  }

  render() {
    return (
      <div>
        <PageHeader pageTitle={this.state.pageTitle} />
  
        <main className={this.props.className}>
          <Container>
            <Row>
              <Column>
                <Form
                  hasContract
                  hasUpdate
                />
              </Column>
            </Row>
          </Container>
        </main>
      </div>
    )
  }
}

const StyledSubscriptionUpdate = styled(SubscriptionUpdate)`
  min-height: 50vh;
`

export default StyledSubscriptionUpdate
