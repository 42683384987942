import styled from 'styled-components'
import { media } from 'components/Grid'

const HamburguerButton = styled.button`
  width: 2.5rem;
  height: 2rem;
  padding: 0;
  margin: 0 0 0 auto;
  background: none;
  border: none;
  border-top: .125rem solid white;
  border-bottom: .125rem solid white;
  border-radius: .125rem;
  position: relative;
  font-size: 0;
  outline: none;
  transition: border-color .2s ease;
  z-index: 10;

  &:before,
  &:after {
    content: '';
    width: 2.5rem;
    height: .125rem;
    background: white;
    border-radius: .125rem;
    position: absolute;
    top: 50%;
    left: 0;
    transform-origin: top left;
    transform: translateY(-50%);
    transition: top .2s ease, transform .2s ease;
  }

  &:before {
    top: -.125rem;
    transform: translateY(0);
  }

  ${props => props.isOpen && `
    border-color: transparent;

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      top: calc(100% - .125rem);
      transform: rotate(-45deg);
    }
  `}

  ${media.desktop`
    display: none;
  `}
`

export default HamburguerButton
