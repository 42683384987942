import React from 'react'
import styled from 'styled-components'

const Textarea = (props) => {
  return (
    <div className={props.className}>
      <label htmlFor={props.name}>{props.label}</label>
      <textarea
        type={props.type}
        name={props.name}
        onChange={(event) => props.onChange(event, props.name)}>
      </textarea>
    </div>
  )
}

const StyledTextarea = styled(Textarea)`
  display: block;
  width: 100%;
  margin-bottom: 1rem;

  label {
    display: block;
  }

  textarea {
    width: 100%;
    max-width: 30rem;
    height: 10rem;
    padding: 1rem;
    border: 1px solid #e5e5e5;
    border-radius: 3px;
    box-shadow: 0 2px 3px rgba(0,0,0,.05);
    color: #666;

    &:focus {
      outline-color: #FFD455;
    }
  }
`

export default StyledTextarea