import React, { Component } from 'react'
import styled from 'styled-components'
import { Container, Row, Column } from 'components/Grid'
import PageHeader from 'components/PageHeader'
import api from 'api/api'
import Form from 'components/Form'

class Tickets extends Component {
  constructor(props) {
    super()

    this.state = {
      pageSlug: '2a-via-de-boleto',
      pageTitle: '',
      pageContent: ''
    }
  }

  componentWillMount() {
    window.scrollTo(0, 0)
    
    api.getPages('', this.state.pageSlug).then(results => {
      if (results.length) {
        this.setState({
          pageTitle: results[0].title.rendered,
          pageContent: results[0].content.rendered
        })
      }
    })
  }

  render() {
    return (
      <div>
        <PageHeader
          pageTitle={this.state.pageTitle}
          isTicketsPage
        />
  
        <main className={this.props.className}>
          <iframe 
            src="https://si-web.sicredi.com.br/boletoweb/BoletoWeb.servicos.Index.task"
            width="100%"
            height="600"
            frameBorder="0"
            style={{ border: 0 }}>
          </iframe>
        </main>
      </div>
    )
  }
}

const StyledTickets = styled(Tickets)`
  min-height: 50vh;
`

export default StyledTickets
