const sizes = {
  tablet: 48,
  desktop: 64,
  desktophd: 90
}

const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => `
      @media (min-width: ${sizes[label]}rem) {
        ${args}
      }`

  return acc
}, {})

export default media
