import axios from 'axios'

const API_BASE_PATH = process.env.REACT_APP_API_BASE_PATH

const ENDPOINTS = {
  PAGES: `${API_BASE_PATH}/wp/v2/pages`,
  BANNERS: `${API_BASE_PATH}/wp/v2/banners`,
  REGIONS: `${API_BASE_PATH}/wp/v2/regions`,
  ALLOTMENTS: `${API_BASE_PATH}/wpr/v1/allotments`,
  GENERAL_INFO: `${API_BASE_PATH}/acf/v3/options/options`,
  SEND_EMAIL: `${API_BASE_PATH}/wpr/v1/send-email`
}

export default {  
  getGeneralConfig() {
    return new Promise(resolve => {
      axios.get(ENDPOINTS.GENERAL_INFO).then(result => {
        return resolve(result.data)
      }).catch(error => {
        return resolve(console.error(error))
      })
    })
  },
  
  getPages(id = '', slug = '') {
    return new Promise(resolve => {
      axios.get(`${ENDPOINTS.PAGES}/${id}?slug=${slug}`).then((result) => {
        return resolve(result.data)
      }).catch((error) => {
        return resolve(console.error(error))
      })
    })
  },

  getBanners() {
    return new Promise(resolve => {
      axios.get(ENDPOINTS.BANNERS).then(result => {
        return resolve(result.data)
      }).catch(error => {
        return resolve(console.error(error))
      })
    })
  },

  getRegions(slug = '') {
    return new Promise(resolve => {
      axios.get(`${ENDPOINTS.REGIONS}?slug=${slug}`).then(result => {
        return resolve(result.data)
      }).catch(error => {
        return resolve(console.error(error))
      })
    })
  },

  getAllotments(slug = '', region = '') {
    return new Promise(resolve => {
      axios.get(`${ENDPOINTS.ALLOTMENTS}?slug=${slug}&region=${region}`).then(result => {
        return resolve(result.data)
      }).catch(error => {
        return resolve(console.error(error))
      })
    })
  },

  sendEmail(emailData) {
    return new Promise(resolve => {
      axios.post(ENDPOINTS.SEND_EMAIL, emailData).then(result => {
        return resolve(result)
      }).catch(error => {
        return resolve(console.error(error))
      })
    })
  }
}
