import React from 'react'
import styled from 'styled-components'
import { media } from 'components/Grid'
import { Link } from 'react-router-dom'

const SecondaryMenu = ({ className, items }) => {
  return (
    <ul className={className}>
      {items.map((item, index) => (
        <li
          key={`item-${index}`}>
          <Link to={item.url}>
            {item.label}
          </Link>
        </li>
      ))}
    </ul>
  )
}

const StyledSecondaryMenu = styled(SecondaryMenu)`
  display: none;

  ${media.desktop`
    display: flex;
    justify-content: flex-end;
    padding: 0 1.4rem 0;
    margin: 0;
    list-style: none;

    > li > a {
      padding: .6rem;
      display: block;
      font-size: .8rem;
      font-weight: 300;
      text-decoration: none;
      letter-spacing: .125rem;
      color: white;
      cursor: pointer;
      outline: none;
    }
  `}
`

export default StyledSecondaryMenu
