import React, { Component } from 'react'
import Banner from 'components/Banner'
import FeaturedAllotments from 'components/FeaturedAllotments'
import AboutUsExcerpt from 'components/AboutUsExcerpt'
import api from 'api/api'

class Home extends Component {
  constructor() {
    super()

    this.state = {
      banners: [],
      featuredAllotments: [],
      aboutUsExcerpt: {
        title: '',
        description: '',
        cta_label: '',
        cta_url: ''
      }
    }
  }

  componentWillMount() {
    window.scrollTo(0, 0)
    
    api.getBanners().then(results => {
      if (results.length > 0) {
        this.setState(prevState => {
          results.map((banner, index) => {
            if (index === 0) {
              return banner.isActive = true
            }

            return banner.isActive = false
          })

          return {
            ...prevState,
            banners: results
          }
        })
      }
    })

    api.getAllotments().then(results => {
      if (results.length > 0) {
        this.setState(prevState => {
          return {
            ...prevState,
            featuredAllotments: results
          }
        })
      }
    })

    api.getPages('', 'quem-somos').then(results => {
      if (results.length > 0) {
        this.setState(prevState => {
          return {
            ...prevState,
            aboutUsExcerpt: {
              title: results[0].title.rendered,
              description: results[0].excerpt.rendered,
              cta_label: 'Saiba mais',
              cta_url: '/quem-somos'
            }
          }
        })
      }
    })
  }

  render() {
    return (
      <main>
        <Banner banners={this.state.banners} />
        <FeaturedAllotments
          allotments={this.state.featuredAllotments}
          isHomePage
        />
        <AboutUsExcerpt content={this.state.aboutUsExcerpt} />
      </main>
    )
  }
}

export default Home;
