import React from 'react'
import styled from 'styled-components'
import { Container, Row, Column } from 'components/Grid'
import Logo from 'components/Logo'
import FacebookLogo from 'assets/images/facebook-logo.svg'
import InstagramLogo from 'assets/images/instagram-logo.svg'
import { Link } from 'react-router-dom'

const Footer = props => {
  return (
    <footer className={props.className}>
      <Container>
        <Row>
          <Column>
            <Logo />
          </Column>
          <Column>
            <h3>Mapa do site</h3>
            <ul>
              {props.mainMenu.items.map((item, index) => (
                <li
                  key={`item-${index}`}
                  item={item}
                  index={index}>
                  {item.target ? (
                    <a href={item.url} target="_blank">
                      {item.label}
                    </a>
                  ) : (
                    <Link to={item.children && item.children.length > 0 ? '#' : item.url}>
                      {item.label}
                    </Link>
                  )}

                  {item.children && item.children.length > 0 &&
                    <ul>
                      {item.children.map((child, index) => (
                        <li key={`child-${index}`}>
                          <Link to={child.url}>{child.label}</Link>
                        </li>
                      ))}
                    </ul>
                  }
                </li>
              ))}

              {props.secondaryMenu.items.map((item, index) => (
                <li
                  key={`secondary-item-${index}`}
                  item={item}
                  index={index}>
                  <Link to={item.url}>
                    {item.label}
                  </Link>
                </li>
              ))}
            </ul>
          </Column>
          <Column>
            <h3>Contato</h3>

            <p>{props.siteInfo.office_hours}</p>

            <p>{props.siteInfo.phones}</p>

            <p>
              <a href={`mailto:${props.siteInfo.email}`}>{props.siteInfo.email}</a>
            </p>

            <p>{props.siteInfo.address}</p>
          </Column>
          <Column>
            <h3>Redes sociais</h3>

            <ul className="social-media">
              <li>
                <a 
                  href={props.siteInfo.facebook}
                  target="_blank"
                  rel="noopener noreferrer">
                  <img src={FacebookLogo} alt="Facebook Logo" />
                </a>
              </li>
              <li>
                <a 
                  href={props.siteInfo.instagram}
                  target="_blank"
                  rel="noopener noreferrer">
                  <img src={InstagramLogo} alt="Instagram Logo" />
                </a>
              </li>
            </ul>
          </Column>
        </Row>
      </Container>
    </footer>
  )
}
const StyledFooter = styled(Footer)`
  padding: 4rem 0;
  margin-top: 65px;
  background: #000000;
  background: -moz-linear-gradient(top, rgb(208,12,0) 0%, #231c1a 100%);
  background: -webkit-linear-gradient(top, rgb(208,12,0) 0%,#231c1a 100%);
  background: linear-gradient(to bottom, rgb(208,12,0) 0%,#231c1a 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='rgb(208,12,0)', endColorstr='#231c1a',GradientType=0 );
  color: white;

  ${props => props.isHomePage && `
    margin-top: 0;
  `}

  h3 {
    font-family: 'Poppins';
    font-size: 1.5rem;
    font-weight: 100;
    margin-bottom: 2rem;
  }

  a {
    color: white;
    text-decoration: none;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;

    > li {
      margin: 1rem 0;
    }

    ul {
      padding-left: 1rem;

      a {
        opacity: .7;
      }
    }
  }

  .social-media {
    > li {
      margin: 0 1rem 0 0;
      display: inline-block;
    }
  }
`

export default StyledFooter
