import React, { Component } from 'react'
import styled from 'styled-components'
import { media, Container } from 'components/Grid'
import api from 'api/api'

class PageHeader extends Component {
  constructor(props) {
    super()

    this.state = {
      background: ''
    }
  }

  componentWillMount() {
    api.getGeneralConfig().then(results => {
      this.setState({
        background: results.acf.header_background
      })
    })
  }

  render() {
    return (
      <header
        className={this.props.className}
        style={{backgroundImage:`url(${this.state.background})`}}>
        <Container>
          <h1>{this.props.pageTitle}</h1>
        </Container>
      </header>
    )
  }
}

const StyledPageHeader = styled(PageHeader)`
  padding: 100px 0 35px;
  margin-bottom: 65px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  color: white;

  ${media.tablet`
    padding: 200px 0 65px;
  `}

  ${props => props.isTicketsPage && `
    margin-bottom: 0;
  `}

  h1 {
    font-family: 'Poppins';
    font-weight: 300;
    margin: 0;
  }
`

export default StyledPageHeader