import React from 'react'
import styled from 'styled-components'

const Button = (props) => {
  return (
    <a
      disabled={props.disabled}
      className={props.className}
      href={props.url}
      onClick={props.callback}>
      {props.children}
    </a>
  )
}

const StyledButton = styled(Button)`
  padding: 1rem 3rem;
  background: #FFD455;
  border-radius: .125rem;
  display: inline-block;
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0.15rem;
  text-transform: uppercase;
  text-decoration: none;
  color: #825505;
  box-shadow: .125rem 0 .125rem rgba(0, 0, 0, .15);

  ${props => props.disabled && `
    opacity: .5;
    mouse-events: none;
  `}
`

export default StyledButton
